import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

const Background = styled('div')``;
const Title = styled('h1')``;
const Description = styled('p')``;
const Img = styled('img')``;

const Header = ({ className }) => (
  <Background className={className}>
    <Img alt="Close menu" src="/images/WFTO.png" />
    <div>
      <Title capitalize translate="SIGNUP_TITLE">
        SIGNUP_TITLE
      </Title>
      <Description translate="SIGNUP_DESCRIPTION">
        SIGNUP_DESCRIPTION
      </Description>
    </div>
  </Background>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  text-align: center;
  color: white;
  padding: 3.2rem 0 3.2rem;
  display: block;
  position: relative;
  min-height: 26rem;

  &::before {
    z-index: 1;
    content: '';
    background: linear-gradient(0deg, #003566, #003566),
      linear-gradient(225deg, #00284d, #003566);
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  div {
    margin-top: -10%;
  }

  ${Img} {
    margin-left: 10%;
    width: 20rem;
    height: 20rem;
    display: block;
    position: relative;
    z-index: 2;
  }

  ${Title} {
    font-size: 5.6rem;
    line-height: 6.4rem;
    position: relative;
    z-index: 2;
  }

  ${Description} {
    font-size: 2.2rem;
    line-height: 2.4rem;
    margin: 2.4rem auto 0;
    max-width: 73.3rem;
    position: relative;
    z-index: 2;
  }

  @media screen and (max-width: 1400px) {
    ${Img} {
      margin-left: 9%;
      width: 18rem;
      height: 18rem;
      position: relative;
      z-index: 2;
    }
    div {
      margin-top: -10%;
    }
  }

  @media screen and (max-width: 1364px) {
    ${Img} {
      margin-left: 5%;
      width: 15rem;
      height: 15rem;
      position: relative;
      z-index: 2;
    }
    div {
      margin-top: -11%;
    }
  }

  @media screen and (max-width: 1110px) {
    ${Img} {
      margin-left: 2%;
      width: 12rem;
      height: 12rem;
      position: relative;
      z-index: 2;
    }
    div {
      margin-top: -12%;
    }
    ${Description} {
      font-size: 2.2rem;
      line-height: 2.4rem;
      margin: 2.4rem auto 0;
      max-width: 67.3rem;
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (max-width: 900px) {
    ${Img} {
      display: inline;
      margin-left: 0;
      z-index: 2;
    }
    div {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 6.4rem 0 5.6rem;

    ${Img} {
      position: relative;
      z-index: 2;
    }

    ${Title} {
      font-size: 3.4rem;
      line-height: 4rem;
      position: relative;
      z-index: 2;
    }

    ${Description} {
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 2.4rem auto 0;
      max-width: 31.1rem;
      position: relative;
      z-index: 2;
    }
  }
`;
