import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { FormattedMessage, intlShape, withIntl } from '@react-intl';

import Loader from 'Components/Loader';

import Header from './Header';
import ThankYou from './ThankYou';

const Container = styled('section')``;
const Card = styled('section')``;

const Error = styled('p')``;
const Title = styled('h3')``;
const Description = styled('p')``;
const Form = styled('form')``;
const Input = styled('input')``;
const Button = styled('button')``;
const Value = styled('span')``;

const ThankYouDescription = styled('p')``;
const ThankYouTitle = styled('h2')``;

class Demo extends Component {
  static propTypes = {
    className: PropTypes.string,
    intl: intlShape,
    location: PropTypes.shape({
      href: PropTypes.string,
    }),
  };

  state = { error: false, loading: false, submit: false };

  handleSubmit = async event => {
    event.preventDefault();

    const data = new FormData(event.target);

    if (!data.get('email') || !data.get('name') || !data.get('companyName')) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false, loading: true });

      await fetch(
        'https://hxcuzzns2j.execute-api.eu-west-1.amazonaws.com/default/sendmail',
        {
          body: JSON.stringify({
            email: data.get('email'),
            name: data.get('name'),
            companyName: data.get('companyName'),
            fromPage: 'WFTO',
          }),
          credentials: 'omit',
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        },
      );

      this.setState({ loading: false, submit: true });
    }
  };

  render() {
    const { className, intl } = this.props;
    const { error, loading, submit } = this.state;

    return (
      <section className={className}>
        <Header />
        <Container>
          {submit ? (
            <ThankYou />
          ) : (
            <Card>
              <FormattedMessage
                capitalize
                component={Description}
                id="SIGNUP_FORM_TITLE"
              >
                Sign up to learn more and launch your business to heights never
                seen before and start selling socially
              </FormattedMessage>
              <Form onSubmit={this.handleSubmit}>
                <Input
                  validate
                  name="name"
                  type="text"
                  required="required"
                  placeholder={intl.formatMessage({
                    capitalize: true,
                    id: 'NAME',
                  })}
                />
                <Input
                  validate
                  name="email"
                  type="email"
                  required="required"
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  placeholder={intl.formatMessage({
                    capitalize: true,
                    id: 'EMAIL',
                  })}
                />
                <Input
                  validate
                  name="companyName"
                  type="text"
                  required="required"
                  placeholder={intl.formatMessage({
                    capitalize: true,
                    id: 'COMPANY_NAME',
                  })}
                />
                {error && <Error>Please, fill all fields.</Error>}
                <Button>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Value capitalize translate="SIGNUP_TITLE">
                      SIGNUP_TITLE
                    </Value>
                  )}
                </Button>
              </Form>
            </Card>
          )}
        </Container>
      </section>
    );
  }
}

export default styled(withIntl(Demo))`
  background-image: url(/images/signup_request_a_demo.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #023565;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 35rem - 8.2rem);

  ${Error} {
    font-size: 1.6rem;
    line-height: 1.5;
    color: #cc0000;
    margin-top: 0;
    opacity: 0.6;
  }

  ${Container} {
    align-items: center;
    background: white;
    display: flex;
    min-height: 68vh;
    justify-content: center;
    padding: 4rem;
  }

  ${Card} {
    margin-top: -8rem;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 0.8rem;
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 53, 102, 0.04),
      0 0.2rem 0.4em 0 rgba(0, 53, 102, 0.08);
    padding: 4rem;
    max-width: 58.4rem;

    ${Title} {
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 3.2rem;
    }

    ${Description} {
      font-size: 1.6rem;
      line-height: 1.5;
      color: #000f19;
      margin-top: 0;
      opacity: 0.6;
    }

    ${ThankYouTitle} {
      font-size: 3.4rem;
      font-weight: 400;
      letter-spacing: -0.15rem;
      line-height: 4rem;
      margin-bottom: 1.6rem;
    }

    ${ThankYouDescription} {
      font-size: 1.6rem;
      line-height: 1.5;
      letter-spacing: 0;
      margin: 0 0 2.4rem;
      opacity: 0.6;

      &:last-of-type {
        margin: 0;
      }
    }

    ${Form} {
      display: grid;
      grid-template: auto;
      grid-gap: 1.6rem;
      width: 100%;
      margin-top: 3.2rem;

      ${Input} {
        grid-column: span 2;
        background: none;
        border: solid 0.1rem #e6e7e8;
        border-radius: 0.6rem;
        cursor: auto;
        display: block;
        font-size: 1.4rem;
        height: 4.8rem;
        line-height: 1.14;

        outline: 0;
        padding: 1.6rem;

        ::placeholder {
          opacity: 0.6;
        }

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 1000px transparent inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }

      ${Button} {
        background-color: #0084ff;
        border-radius: 0.6rem;
        color: white;
        cursor: pointer;
        font-size: 1.4rem;
        height: 4.8rem;
        margin-top: 4rem;
        outline: 0;
        width: 17.6rem;
        justify-self: center;
        grid-column: span 2;
      }
    }

    ${Button} {
      background-color: white;
      border: solid 1px #0084ff;
      border-radius: 0.6rem;
      color: #0084ff;
      font-size: 1.4rem;
      margin-top: 8rem;
      height: 4rem;
      opacity: 0.8;
      outline: 0;
      width: 17.6rem;
    }
  }

  @media screen and (min-width: 600px) {
    ${Container} {
      padding: 8rem 11.2rem;

      ${Form} {
        grid-template: repeat(4, auto) / 1fr 1fr;
        grid-gap: 1.6rem 2.4rem;

        ${Input} {
          grid-column: span 2;
        }

        ${Button} {
          grid-row: 4;
          margin-top: 2.4rem;
          outline: 0;
          width: 17.6rem;
        }
      }
    }

    ${ThankYouTitle} {
      font-size: 4rem;
      font-weight: 500;
      line-height: 4.8rem;
      letter-spacing: -0.15rem;
      margin-bottom: 4rem;
    }
  }

  @media screen and (min-width: 1920px) {
    background-size: 100%;
    ${Container} {
      padding: 8rem 20%;
      height: 55vh;
    }
  }
`;
